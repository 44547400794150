// angular import
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// project import
import { AdminComponent } from './layouts/admin/admin.component';
import { EmptyComponent } from './layouts/empty/empty.component';
import { AuthGuard } from './services/guards/auth.guard';
import { NotAuthGuard } from './services/guards/notauth.guard';
import { RoleGuard } from './services/helpers/role.guard';
import { AdminWrapperComponent } from './pages/admin/admin-wrapper/admin-wrapper.component';
import { ClientComponent } from './layouts/customer/client.component';
import { NotFoundComponent } from './layouts/404/not-found.component';
import { CheckLayoutComponent } from './layouts/check-layout/check-layout.component';

const routes: Routes = [
  {
    path: 'public',
    component: ClientComponent,
    children: [
      {
        path: 'documentation',
        loadComponent: () => import('./pages/shared/documentation/documentation.component').then((m) => m.DocumentComponent)
      },
      {
        path: 'freelancers',
        loadComponent: () => import('./pages/shared/freelancers/freelancers.component').then((m) => m.FreelancersComponent)
      },
      {
        path: 'sellers',
        loadComponent: () => import('./pages/shared/sellers/sellers.component').then((m) => m.SellersComponent)
      },
      {
        path: 'privacy-policy',
        loadComponent: () => import('./pages/shared/privacy-policy/privacy-policy.component').then((m) => m.PrivacyPolicyComponent)
      },
      {
        path: 'terms',
        loadComponent: () => import('./pages/shared/terms/terms.component').then((m) => m.TermsComponent)
      },
      {
        path: 'prices',
        loadComponent: () => import('./pages/shared/prices/prices.component').then((m) => m.PricesComponent)
      },
      {
        path: 'faq',
        loadComponent: () => import('./pages/shared/faq/faq.component').then((m) => m.FAQComponent)
      },
      {
        path: 'contact-us',
        loadComponent: () => import('./pages/shared/contact-us/contact-us.component').then((m) => m.ContactUsComponent)
      },
      {
        path: 'about',
        loadComponent: () => import('./pages/shared/about/about.component').then((m) => m.AboutComponent)
      },
      {
        path: 'blog',
        loadComponent: () => import('./pages/shared/blog/blog.component').then((m) => m.BlogComponent)
      },
      // {
      //   path: 'partnership',
      //   loadComponent: () => import('./pages/shared/partnership/partnership.component').then((m) => m.PartnershipComponent)
      // },
      {
        path: 'cases',
        loadComponent: () => import('./pages/shared/cases/cases.component').then((m) => m.CasesComponent)
      },
    ]
  },
  {
    path: '',
    component: CheckLayoutComponent
  },

  {
    path: '',
    component: ClientComponent,
    canActivate: [NotAuthGuard],
    children: [
      {
        path: 'landing-buyer',
        loadComponent: () => import('./pages/client/landing-buyer/landing-buyer.component').then((m) => m.LandingBuyerComponent)
      },
      {
        path: 'landing-seller',
        loadComponent: () => import('./pages/client/landing-seller/landing-seller.component').then((m) => m.LandingSellerComponent)
      }
    ]
  },
  {
    path: '',
    component: ClientComponent,
    children: [
      {
        path: '',
        children: [
          {
            path: 'my-profile',
            canActivate: [AuthGuard],
            loadChildren: () => import('./pages/shared/user-profiles/user-profiles.module').then((m) => m.UserProfilesModule)
          },

          {
            path: 'notifications',
            canActivate: [AuthGuard],
            loadComponent: () => import('./pages/client/notifications/notifications.component').then((m) => m.NotificationsComponent)
          },
          {
            path: 'account-menu',
            canActivate: [AuthGuard],
            loadComponent: () => import('./shared/components/side-bar-menu/side-bar-menu.component').then((m) => m.SideBarMenuComponent)
          },
          {
            path: 'order',
            canActivate: [AuthGuard],
            data: { redirectLogin: true },
            loadChildren: () => import('./pages/shared/orders/orders.module').then((m) => m.OrdersModule)
          },
          {
            path: 'transaction',
            canActivate: [AuthGuard],
            data: { redirectLogin: true },
            loadChildren: () => import('./pages/shared/transaction/transaction.module').then((m) => m.TransactionModule)
          },
          {
            path: 'messenger',
            canActivate: [AuthGuard],
            data: { redirectLogin: true },
            loadChildren: () => import('./pages/shared/messenger/messenger.module').then((m) => m.MessengerModule)
          },

          {
            path: 'buyer-landing-buyer',
            loadChildren: () => import('./pages/buyer/buyer-home.module').then((m) => m.BuyerHomeModule)
          },
          {
            path: 'seller-landing-buyer',
            canActivate: [AuthGuard],
            data: { redirectLogin: true },
            loadChildren: () => import('./pages/seller/seller-home.module').then((m) => m.SellerHomeModule)
          },
          {
            path: 'profile/:id/:profileType',
            canActivate: [AuthGuard],
            data: { redirectLogin: true },
            loadChildren: () => import('./pages/shared/user-public-profile/user-profiles.module').then((m) => m.UserProfilesModule)
          },
          {
            path: 'category',
            data: { redirectLogin: true },
            loadComponent: () => import('./shared/components/category/category.component').then((m) => m.CategoryComponent)
          },
          {
            path: 'category/:id',
            data: { redirectLogin: true },
            loadComponent: () =>
              import('./pages/buyer/announcements/buyer-announcement.component').then((m) => m.BuyerAnnouncementComponent)
          }
        ]
      },
      {
        path: 'onboarding',
        canActivate: [AuthGuard],
        data: { redirectLogin: true },
        loadChildren: () => import('./pages/shared/onboarding/onboarding.module').then((m) => m.OnboardingModule)
      },

      {
        path: 'about',
        loadComponent: () => import('src/app/layouts/customer/about/about.component').then((c) => c.AboutComponent)
      }
    ]
  },
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: 'admin',
        data: { roles: ['admin'] },
        canActivate: [RoleGuard],
        component: AdminWrapperComponent,
        children: [
          {
            path: 'announcement',
            loadComponent: () => import('./pages/admin/announcement/announcement.component').then((c) => c.AnnouncementComponent)
          },
          {
            path: 'add',
            loadComponent: () =>
              import('./pages/admin/announcement/add-announcement/add-announcement.component').then((c) => c.AddAnnouncementComponent)
          },
          {
            path: 'edit/:id',
            loadComponent: () =>
              import('./pages/admin/announcement/add-announcement/add-announcement.component').then((c) => c.AddAnnouncementComponent)
          },
          {
            path: 'details/:id',
            loadComponent: () =>
              import('./pages/admin/announcement/announcement-details/announcement-details.component').then(
                (c) => c.AnnouncementDetailsComponent
              )
          },
          {
            path: 'products',
            loadComponent: () => import('./pages/admin/product/product.component').then((m) => m.ProductComponent)
          },

          {
            path: 'products/details/:id',
            loadComponent: () =>
              import('./pages/admin/product/product-details/product-details.component').then((m) => m.ProductDetailsComponent)
          },
          {
            path: 'users',
            loadChildren: () => import('./pages/admin/user/user-routing.module').then((m) => m.UserRoutingModule)
          },

          {
            path: 'shop',
            loadChildren: () => import('./pages/admin/shop/shop.module').then((m) => m.ShopModule)
          },
          {
            path: 'category',
            loadChildren: () => import('./pages/admin/category/category.module').then((m) => m.CategoryModule)
          },
          {
            path: 'task',
            loadChildren: () => import('./pages/admin/task/task.module').then((m) => m.TaskModule)
          },

          {
            path: 'withdraw',
            loadChildren: () => import('./pages/admin/withdraws/withdraw.module').then((m) => m.WithdrawModule)
          },

          {
            path: 'order',
            loadChildren: () => import('./pages/shared/orders/orders.module').then((m) => m.OrdersModule)
          },
          {
            path: 'transaction',
            loadChildren: () => import('./pages/shared/transaction/transaction.module').then((m) => m.TransactionModule)
          },
          {
            path: 'profile/:id/:profileType',
            loadChildren: () => import('./pages/shared/user-public-profile/user-profiles.module').then((m) => m.UserProfilesModule)
          }
        ]
      }
    ]
  },
  {
    path: 'auth',
    component: EmptyComponent,
    canActivate: [NotAuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/auth/authentication/authentication.module').then((e) => e.AuthenticationModule)
      }
    ]
  },

  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
